<template>
  <div>
    <ul class="tab-list">
      <li
          v-for="(tab, index) in tabList"
          :key="index"
          class="tab-item"
          :class="{
          'tab-item-active': index + 1 === activeTab,
        }"
      >
        <label
            :for="`${index}`"
            v-text="tab"
            class="cursor-pointer block"
        />
        <input
            :id="`${index}`"
            type="radio"
            :name="`tab-${index}`"
            :value="index + 1"
            v-model="activeTab"
            class="hidden"
        />
      </li>
    </ul>

    <!-- CONTENT -->
    <template v-for="(tab, index) in tabList">
      <div
          :key="index"
          v-if="index + 1 === activeTab"
      >
        <slot :name="`tabPanel-${index + 1 }`" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      activeTab: 1
    };
  },
};
</script>

<style scoped>
.tab-list {
  @apply
  flex
  flex-wrap
  lg:border-b
  lg:border-gray-800
  lg:dark:border-primary-dark
}

.tab-item {
  @apply
  text-left
  text-sm
  w-max
  text-primary-light
  dark:text-primary-dark
  cursor-pointer
  whitespace-nowrap
  border
  m-1
  rounded
  border-primary-light
  dark:border-primary-dark
  lg:border-t-0
  lg:border-l-0
  lg:border-r-0
  lg:border-b-2
  lg:rounded-none
  lg:m-0
  font-medium
  py-2
  px-4
  hover:bg-gray-200
  hover:border-primary-light
}

.tab-item-active {
  @apply
  bg-gray02-40
  dark:bg-gray-800
  text-primary-light
  dark:text-primary-dark
  border-[#0000A0]
}
</style>